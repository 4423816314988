<template>
  <div>
    <ClientPackagesList :packages="generatedPackages" :loading="getPackagesInProgress">
      <template #title>
        <div class="d-flex justify-space-between align-center">
          <span class="text-h6"> Package generation </span>
        </div>
      </template>
      <template #desc>
        <p>
          By default, Patriot will generate draft packages. Once the draft is accepted, enable
          <b>Final</b> below to generate a final package.
        </p>
      </template>
      <template #delete-button="{packageId}">
        <v-btn
            :loading="deleteInProgress"
            class="ml-2"
            type="button"
            @click="deletePackage(packageId)"
            icon
        >
          <v-icon> mdi-close</v-icon>
        </v-btn>
      </template>
      <template #empty-text>
        <p class="text-caption">NO PACKAGES FOUND</p>
      </template>
      <template #actions>
        <div v-if="isAdmin" class="d-flex justify-end align-end mb-3 mr-2">
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-switch
              v-model="isDraft"
              label="Final"
              color="red"
              class="switchDraftOrFinal my-0 mr-3"
              hide-details
            />
            <v-btn
              id="generatePackageBtn"
              color="primary"
              @click.native="generatePackage"
              :loading="generationInProgress"
              :disabled="generatePackageButtonDisabled"
            >
              GENERATE PACKAGE
            </v-btn>
          </div>
        </div>
      </template>
    </ClientPackagesList>
    <br>
    <ClientPackagesList :packages="type2020supplements" :loading="getPackagesInProgress">
      <template #title>
        <div class="d-flex justify-space-between align-center">
          <span class="text-h6">2020 Supplements</span>
        </div>
      </template>
      <template #delete-button="{packageId}">
        <v-btn
            :loading="deleteInProgress"
            class="ml-2"
            type="button"
            @click="deletePackage(packageId)"
            icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template #empty-text>
        <p class="text-caption">NO PACKAGES FOUND</p>
      </template>
    </ClientPackagesList>
<!--    <br>-->
  </div>

</template>

<script>
import PackageService from "@/services/packages.service";
import ClientService from "@/services/clients.service";
import ClientPackagesList from "@/components/Client/Profile/ClientPackagesList.vue";

export default {
  name: "ClientPackages",
  components: {
    ClientPackagesList,
  },
  props: {
    clientId: {
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    generatePackageButtonDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    generationInProgress: false,
    deleteInProgress: false,
    getPackagesInProgress: false,
    isDraft: false,
    generatedPackages: [],
    type2020supplements: [],
    type4506files: [],
    type4506Loading: false,
    generation4506inProgress: false,
  }),
  methods: {
    getAllPackages() {
      this.getPackagesInProgress = true;
      this.type2020supplements = [];
      this.generatedPackages = [];
      return PackageService.getPackages(this.clientId)
        .then((data) => {
          if (data) {
            data.forEach(e => {
              if (e.status === '2020-supplement')
                this.type2020supplements.push(e)
              else
                this.generatedPackages.push(e)
            })
          }
        })
        .finally(() => (this.getPackagesInProgress = false));
    },
    deletePackage(id) {
      this.deleteInProgress = true;
      return PackageService.deletePackage(id)
        .then((res) => {
          this.getAllPackages();
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Package was deleted successfully.`,
            duration: 4000,
            mode: "success",
          });
        })
        .finally(() => (this.deleteInProgress = false));
    },
    generatePackage() {
      let typeGeneration = this.isDraft ? "final" : "draft";

      this.generationInProgress = true;
      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: `Package generation scheduled successfully.`,
        duration: 4000,
        mode: "success",
      });
      ClientService.postPackage(this.clientId, { request: typeGeneration })
        .then((res) => {
          this.getAllPackages();
          this.generationInProgress = false;
        })
        .finally(() => (this.generationInProgress = false));
    }
  },
  created() {
    this.getAllPackages();
  },
};
</script>

<style scoped>
  .bordered {
    border: 1px solid rgba(0, 0, 0, 0.13);
  }
</style>

import $axios from "@/plugins/axios";

class AdoptionsService {
  createAdoption(payload) {
    return $axios.post('/adoptions', payload).then(res => res.data)
  }

  getAdoptions(entityId) {
    return $axios.get(`/entity/${entityId}/adoptions`).then(res => res.data)
  }

  deleteAdoption(id) {
    return $axios.delete(`/adoptions/${id}`)
  }
}

export default new AdoptionsService()
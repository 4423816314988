import $axios from '../plugins/axios'

class PackageService {

	getPackages(id) {
		return $axios.get(`/clients/${id}/packages`)
			.then((res) => {
				return res.data;
			});
	}

	deletePackage(id) {
		return $axios.delete(`/packages/${id}`,)
			.then((res) => {
				// return res.data;
			});
	}
}

export default new PackageService();
